/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

// Sticky Header
$(window).scroll(function() {
  if ($(this).scrollTop() > 50){ 
      $('#main-site-header').addClass("sticky");
      if ($("#search-bar").hasClass("show-search")) {
        $('#search-bar').addClass("sticky");
      }
    }
    else{
      $('#main-site-header').removeClass("sticky");
      if ($("#search-bar").hasClass("show-search")) {
        $('#search-bar').removeClass("sticky");
      }
    }
  });

// Show Search
$( "#btn-search" ).click(function() {
  // $( "#search-box" ).focus();
  // $('#search-box').val('');
  $('#search-bar').toggleClass("show-search");
  $('#btn-search').toggleClass("selected");
  $('i.ico-show').toggleClass("hidden");
  $('i.ico-hidden').toggleClass("show");

  if ($("#main-site-header").hasClass("sticky")) {
    $('#search-bar').toggleClass("sticky");
  }
});

// Show Cart Total
$( "#btn-cart" ).hover(function() {
  $('#cart-contents').toggleClass("show-cart");
});

$( "#cart-contents" ).hover(function() {
  $('#cart-contents').toggleClass("show-cart");
});

$("#click-here-shipping").click(function () {

    $header1 = $(this);
    //getting the next element
    $content1 = $('.product-shipping-holder');
    //open up the content needed - toggle the slide- if visible, slide up, if not slidedown.
    $content1.slideToggle(500, function () {
        
        $('#click-here-shipping i').toggleClass('mdi-plus');
        $('#click-here-shipping i').toggleClass('mdi-minus');
        
    });

});

// Mobile Menu
$( "#btn-menu" ).click(function() {
  $('body').toggleClass("no-scroll");
  $('#mobile-menu').toggleClass("show");
  $('.ico-show.mdi-menu').toggleClass("hidden");
  $('.ico-hidden.mdi-close').toggleClass("show");
  $('#btn-menu').toggleClass("selected");
});

$("#btn-signup").click(function () {

    $('html,body').animate({ scrollTop: 9999 }, 'slow');

});

$("#click-here-returns").click(function () {

    $header2 = $(this);
    //getting the next element
    $content2 = $('.product-returns-holder');
    //open up the content needed - toggle the slide- if visible, slide up, if not slidedown.
    $content2.slideToggle(500, function () {
        
        $('#click-here-returns i').toggleClass('mdi-plus');
        $('#click-here-returns i').toggleClass('mdi-minus');
        
    });

});

$('.dropdown').hover(
        function() {
            $(this).find('.dropdown-menu').stop(true, true).delay(100).fadeIn();
        }, 
        function() {
            $(this).find('.dropdown-menu').stop(true, true).delay(100).fadeOut();
        }
    );

    $('.dropdown-menu').hover(
        function() {
            $(this).stop(true, true);
        },
        function() {
            $(this).stop(true, true).delay(300).fadeOut();
        }
    );

})(jQuery); // Fully reference jQuery after this point.





